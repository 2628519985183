<template>
	<div id="starbucks">
		<Header2022 :navbg='true'></Header2022>
		<div class="banner">
			<div class="">
				<div class="title">小院咖啡服务</div>
				<div class="subtitle">给行程匆忙的你 一份心灵的慰藉</div>
			</div>
			<div class="centent">
				小院咖啡，隶属于西安小院生活馆商业运营管理有限公司轻业态事业部核心业务， 主旨为打造以健康、绿色为主的轻食吧台服务平台，平台内包含小院精品咖啡服务产品线：小院咖啡、鲜榨果汁、原创甜品蛋糕以及健康轻食等，
				同时与头部咖啡品牌进行深度合作，将其优秀的产品线融入小院咖啡平台中，为顾客打造全方位高品质产品服务。
				同时小院咖啡平台除优质产品服务之外，拥有自主研发线上小程序，以及专业咖啡吧台运营与管理团队， 一方面为顾客提供贴心、智能的服务，另一方面为有建设咖啡吧、饮品吧需求的酒店、企事业单位等客户提供一站式运营服务。
			</div>
			<el-popover placement="top" width="300" trigger="hover">
				<img src="../../assets/images/xiaochengxu-lifehall.png">
				<el-button slot="reference">详情联系我们</el-button>
			</el-popover>
		</div>
		<div class="starbucks-stores">
			<div class="headtitle">小院生活咖啡服务门店</div>
			<div class="store-list">
				<div class="store-item" v-for="(starItem,starIndex) in starbucksStores" :key="starIndex">
					<img :src="starItem.image">
					<div class="name">{{starItem.name}}</div>
					<div class="address">{{starItem.address}}</div>
					<div class="introduce">{{starItem.intr}}</div>
				</div>
			</div>
		</div>
		<div class="stores">
			<div class="headtitle">小院生活自营精品生活馆</div>
			<stores :img="img" :imgArr="imgArr"></stores>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import stores from '@/components/store-list.vue'
	export default {
		components: {
			Header2022,
			Footer2022,
			stores
		},
		data() {
			return {
				starbucksStores: [{
						image: require("../../assets/images/life/starbucks1.png"),
						name: '小院生活·新华书店',
						address: '西安建筑科技大学草堂校区店',
						intr: '位于西安建筑科技大学草堂校区图书馆2F，总面积300平米，集咖啡饮品、图书借阅、特色文创、共享空间、精品小食等品类于一体，可以满足草堂校区师生的多样化需求。'
					},
					{
						image: require("../../assets/images/life/starbucks2.png"),
						name: '小院生活大雁塔店',
						address: '西安·曲江·大雁塔音乐喷泉旁·帛薇酒店大堂',
						intr: '位于西安市曲江大雁塔音乐喷泉帛薇酒店大堂，打造以“共享书架”为中心的酒店文化，秉承公益服务的中心主旨，配套各项业态的增值服务，集咖啡服务、图书借阅、共享办公、共享空间、沙龙活动、等品类于一体，可以满足多样化需求。'
					}
				],
				img: [{
						image: require("../../assets/images/life/store1.png"),
						name: '小院图书馆·水岸花城店',
						address: '西安市泾渭新城泾渭街道泾渭五路1号',
						opentime: '9:00-18:00',
						content:'打造以“共享书架”为中心的社区书屋，秉承公益服务的中心主旨，配套各项业态的增值服务，建设覆盖泾河工业园区，重点集中服务于水岸花城社区及西安高新泾河小学为一体的文化生活圈。集咖啡服务、图书借阅、共享办公、共享空间、沙龙活动、等品类于一体，可以满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store5.png"),
						name: '小院图书馆·奥韵华府店',
						address: '西安市高陵区昭慧路奥韵华府',
						opentime: '9:00-18:00',
						content:'小院图书馆 · 奥韵华府店位于西安市高陵区昭慧路293号。门店目前现有区域划分为：图书阅读空间、沙龙活动、盲人观影室以及咖啡服务等满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store6.png"),
						name: '小院生活·大雁塔店',
						address: '西安市曲江大雁塔音乐喷泉帛薇酒店大堂',
						opentime: '8:00-20:00',
						content:'位于西安市曲江大雁塔音乐喷泉帛薇酒店大堂，打造以“共享书架”为中心的酒店文化，秉承公益服务的中心主旨，配套各项业态的增值服务，集咖啡服务、图书借阅、共享办公、共享空间、沙龙活动、等品类于一体，可以满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store7.png"),
						name: '小院生活·新华书店',
						address: '西安建筑科技大学草堂校区店',
						opentime: '10:00-22:00',
						content:'位于西安建筑科技大学草堂校区图书馆2F，总面积300平米，集咖啡饮品、图书借阅、特色文创、共享空间、精品小食等品类于一体，可以满足草堂校区师生的多样化需求。',
					},
				],
				imgArr: [{
						image: require("../../assets/images/life/store1.png"),
						name: '小院图书馆·水岸花城店',
						address: '西安市泾渭新城泾渭街道泾渭五路1号',
						opentime: '9:00-18:00',
						content:'打造以“共享书架”为中心的社区书屋，秉承公益服务的中心主旨，配套各项业态的增值服务，建设覆盖泾河工业园区，重点集中服务于水岸花城社区及西安高新泾河小学为一体的文化生活圈。集咖啡服务、图书借阅、共享办公、共享空间、沙龙活动、等品类于一体，可以满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store5.png"),
						name: '小院图书馆·奥韵华府店',
						address: '西安市高陵区昭慧路奥韵华府',
						opentime: '9:00-18:00',
						content:'小院图书馆 · 奥韵华府店位于西安市高陵区昭慧路293号。门店目前现有区域划分为：图书阅读空间、沙龙活动、盲人观影室以及咖啡服务等满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store6.png"),
						name: '小院生活·大雁塔店',
						address: '西安市曲江大雁塔音乐喷泉帛薇酒店大堂',
						opentime: '8:00-20:00',
						content:'位于西安市曲江大雁塔音乐喷泉帛薇酒店大堂，打造以“共享书架”为中心的酒店文化，秉承公益服务的中心主旨，配套各项业态的增值服务，集咖啡服务、图书借阅、共享办公、共享空间、沙龙活动、等品类于一体，可以满足多样化需求。',
					},
					{
						image: require("../../assets/images/life/store7.png"),
						name: '小院生活·新华书店',
						address: '西安建筑科技大学草堂校区店',
						opentime: '10:00-22:00',
						content:'位于西安建筑科技大学草堂校区图书馆2F，总面积300平米，集咖啡饮品、图书借阅、特色文创、共享空间、精品小食等品类于一体，可以满足草堂校区师生的多样化需求。',
					},
					{
						image: require("../../assets/images/life/store8.jpg"),
						name: '小院图书馆·经开未来城店',
						address: '渭南市临渭区新城二路与凤华街经开未来城',
						opentime: '10:00-22:00',
						content:'位于渭南市临渭区新城二路与凤华街十字，打造以“共享书架”为中心的社区书屋，秉承公益服务的中心主旨，配套各项业态的增值服务，打造共享书屋，阅读空间，饮品服务，健身，攀岩于一起的文化生活圈，可以满足多样化需求。',
					},
				],
			}
			
		},
		methods:{

		}
	}
</script>

<style lang="scss">
	#starbucks {
		.animated{
		    animation-duration:.5s;
		}
		.banner {
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			background: url('../../assets/images/life/starbucksbg.png') no-repeat;
			background-size: cover;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			.title {
				font-size: 42px;
				font-weight: bold;
				line-height: 42px;
				padding-top: 80px;
				text-align: center;
			}

			.subtitle {
				font-size: 30px;
				font-weight: 300;
				line-height: 30px;
				margin: 20px 0 0;
				text-align: center;
			}

			.centent {
				width: 1102px;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
			}
			@media only screen and (max-width:1440px){
				.centent{
					width: 840px;
					font-size: 16px;
				}
			}
			.el-button{
				font-size: 12px;
				text-align: center;
				cursor: pointer;
				width: 258px;
				color:#FFFFFF;
				background-color: rgba(0, 0, 0, 0);
				height: 42px;
				border: 2px solid #FFFFFF;
				border-radius: 20px;
			}
			.btn {
				font-size: 12px;
				line-height: 42px;
				text-align: center;
				cursor: pointer;
				width: 258px;
				height: 42px;
				border: 2px solid #FFFFFF;
				border-radius: 20px;
			}
		}

		.headtitle {
			font-size: 40px;
			line-height: 40px;
			text-align: center;
			font-weight: bold;
			margin-bottom: 99px;
			color: #000000;
		}

		.starbucks-stores {
			color: #000000;
			padding: 87px 0 174px;
			.store-list {
				display: flex;
				justify-content: center;
				.store-item {
					margin: 0 10px;
					text-align: center;
					width: 30.21%;
					img {
						width: 100%;
						border-radius: 10px;
					}
					.name {
						font-size: 24px;
						line-height: 24px;
						font-weight: bold;
						margin: 50px 0 9px;
					}
					.address {
						font-size: 16px;
						color: #999999;
						line-height: 16px;
					}
					.introduce {
						padding: 0 50px;
						font-size: 16px;
						line-height: 26px;
						margin-top: 40px;
					}
				}
			}
		}
		.stores{
			margin-bottom: 130px;
		}
	}
</style>
