<template>
	<div id="own-list" style="display: flex">
		<img src="../assets/images/life/left.png" class="cut last" @click="left" :disabled="flagLeft" v-if="imgArr.length>4">
		<div class="own-item" v-for="(ownItem,ownIndex) in img" :key="ownIndex">
			<img :src="ownItem.image" class="image">
			<div class="store-intr">
				<div class="name">{{ownItem.name}}</div>
				<template v-if="ownItem.address">
					<div class="address">
						<img src="../assets/images/dingwei.png">
						{{ownItem.address}}
					</div>
					<div class="address">
						<img src="../assets/images/time.png">
						{{ownItem.opentime}}
					</div>
					<el-popover placement="bottom-start" title="" width="300" trigger="hover" :content="ownItem.content">
						<el-button slot="reference">了解更多 ></el-button>
					</el-popover>
				</template>
			</div>
		</div>
		<img src="../assets/images/life/right.png" class="cut next" @click="right" :disabled="flagRight" v-if="imgArr.length>4">
	</div>
</template>

<script>
	export default {
		props: {
			img: Array,
			imgArr: Array,
		},
		data() {
			return {
				numIndex: 3,
				flagLeft: true,
				flagRight: false,
			}
		},
		methods: {
			left() {
				if (this.numIndex == 3) {
					this.flagLeft = true;
					return false;
				}
				this.flagRight = false;
				// console.log("点击了左箭头");
				if (this.numIndex > 3) {
					this.img.unshift(this.imgArr[this.numIndex - 4]); //前面添加一个
					this.img.pop(this.img[this.numIndex]); //后面删除一个，此处的index因为前面添加了一个新数据的缘故，注意看是否为数组的最后一个数据
					// console.log("打印最前面删除的内容", this.imgArr[this.numIndex + 1]);
					this.numIndex--;
					// console.log("循环的数组：", this.img);
					// console.log("打印下标", this.numIndex);
				}
			},
			right() {
				if (this.numIndex == this.imgArr.length - 1) {
					this.flagRight = true;
					return false;
				}
				this.flagLeft = false;
				// console.log("点击了右箭头", this.flagleft);
				this.img.shift(this.img[this.numIndex - 3]); //前面删除一个
				this.img.push(this.imgArr[this.numIndex + 1]); //后面添加一个
				// console.log("打印最后新加的内容", this.imgArr[this.numIndex + 1]);
				this.numIndex++;
				// console.log("循环的数组：", this.img);
				// console.log("打印下标", this.numIndex);
			},
		}

	}
</script>

<style lang="scss">
	#own-list {
		display: flex;
		justify-content: space-around;
		position: relative;
		color: #000000;

		.el-button {
			border: 0;
			color: #000000;
			padding-left: 0;
		}

		.cut {
			width: 40px;
			height: 80px;
			position: absolute;
			top: 18%;

			@media only screen and (max-width:1680px) {
				width: 32px;
				height: 64px;
			}

			@media only screen and (max-width:1440px) {
				width: 28px;
				height: 56px;
			}

			@media only screen and (max-width:1280px) {
				width: 20px;
				height: 40px;
			}
		}

		.next {
			right: 4%;
		}

		.last {
			left: 4%;
		}

		.own-item {
			font-size: 16px;
			line-height: 16px;
			margin: 0 10px;
			width: 19.9%;

			.image {
				width: 100%;
				border-radius: 4%;
			}

			.store-intr {
				padding-left: 7.8%;

				.name {
					font-size: 24px;
					font-weight: bold;
					line-height: 24px;
					margin: 60px 0 39px;
				}

				.address {
					margin-bottom: 19px;

					img {
						width: 13px;
						height: 13px;
						margin-right: 8px;
					}
				}

				.more {
					margin-top: 29px;

					.iconfont {
						font-size: 13px;
					}
				}
			}

			@media only screen and (max-width:1680px) {
				.store-intr {
					padding-left: 10px;

					.name {
						font-size: 18px;
						line-height: 18px;
					}

					.address {
						display: flex;
						align-items: flex-start;
						font-size: 14px;
						line-height: 18px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						img {
							padding-top: 4px;
						}
					}

					.more {
						font-size: 14px;
					}
				}
			}
		}
	}
</style>